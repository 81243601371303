<template>
  <div>
    <a-card hoverable>
      <template slot="actions">
        <a-button type="link" @click="onclickPurchase"><a-icon type="dollar" style="margin-right: 10px" />Purchase</a-button>
      </template>
      <a-card-meta title="Pricing Calculator">
      </a-card-meta>
      <br>
      <a-tabs v-model="payType">
        <a-tab-pane key="pay_per_use" tab="PAY PER USE">
          <p style="text-align: left; font-size: 13px"><i>You will only pay for the number of uses you purchase per cycle. The more you buy, the lower the price, saving up to <b>25%</b>.</i></p>
          <br>
          <a-form>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="Type" class="form-inline form-lable-left">
              <a-form-item
                class="inline-form-item"
                :style="{ display: 'inline-block', width: 'calc(50%)' }"
              >
                <a-select :default-value="type" v-model="type">
                  <a-select-option key="funcaptcha" value="funcaptcha"><a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha</a-select-option>
                  <a-select-option key="image" value="image"><a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text</a-select-option>
                  <a-select-option key="recaptcha_v2" value="recaptcha_v2" disabled><a-avatar src="/images/recaptcha.png" style="margin-right: 5px"></a-avatar>reCaptcha V2 <small>(cooming soon)</small></a-select-option>
                  <a-select-option key="recaptcha_v3" value="recaptcha_v3" disabled><a-avatar src="/images/recaptcha.png" style="margin-right: 5px"></a-avatar>reCaptcha V3 <small>(cooming soon)</small></a-select-option>
                  <a-select-option key="recaptcha_enterprise" value="recaptcha_enterprise" disabled><a-avatar src="/images/recaptcha.png" style="margin-right: 5px"></a-avatar>reCaptcha Enterprise<small>(cooming soon)</small></a-select-option>
                </a-select>
              </a-form-item>
              <span class="custom-form-item-label" :style="{ display: 'inline-block', width: '50px', marginLeft: '12px', marginRight: '14px' }">
                Period:
              </span>
              <a-form-item class="inline-form-item" :style="{ display: 'inline-block', width: 'calc(50% - 76px)' }">
                <a-select :default-value="period" v-model="period">
                  <a-select-option key="365" value="365">365 days</a-select-option>
                </a-select>
              </a-form-item>
            </a-form-item>
            <a-form-item class="a-from-item-label-left" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" :label="type == 'funcaptcha' ? 'Tokens' : 'Images'" >
              <a-slider v-model="quantity" :min="quantityMin" :max="quantityMax" />
            </a-form-item>
            <a-form-item>
              <span slot="label" v-if="quantity >= quantityMin && quantity <= quantityMax">{{`Number of ${type == 'funcaptcha' ? 'Tokens' : 'Images'} you will buy (${quantityMin} - ${quantityMax})`}}</span>
              <span slot="label" style="color: red" v-if="quantity < quantityMin || quantity > quantityMax">
                {{ `Number of ${type == 'funcaptcha' ? 'Tokens' : 'Images'} is invalid, must be (${quantityMin} - ${quantityMax})` }}
              </span>
              <a-input 
                v-model="quantity" 
                type="number"
              >
              </a-input>
            </a-form-item>
    
            <span>Price/1000: <b>{{priceRange ? `$${priceRange.price}` : '~'}}</b></span>
            <br>
            <span>Costs: <b>${{parseFloat(costs.toFixed(5))}}</b></span>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="pay_per_thread" tab="PAY PER THREAD">
          <p style="text-align: left; font-size: 13px"><i>You will pay for each thread you purchase per cycle. <b>Unlimited</b> uses, but the number of <b>concurrencies</b> uses will be limited by the number of threads you purchase.</i></p>
          <br>
          <a-form>
            <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" label="Type" class="form-inline form-lable-left">
              <a-form-item
                class="inline-form-item"
                :style="{ display: 'inline-block', width: 'calc(50%)' }"
              >
                <a-select :default-value="unlimited.type" v-model="unlimited.type">
                  <a-select-option key="image" value="image"><a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text</a-select-option>
                  <a-select-option key="recaptcha_v2" value="recaptcha_v2" disabled><a-avatar src="/images/recaptcha.png" style="margin-right: 5px"></a-avatar>reCaptcha V2 <small>(cooming soon)</small></a-select-option>
                  <a-select-option key="recaptcha_v3" value="recaptcha_v3" disabled><a-avatar src="/images/recaptcha.png" style="margin-right: 5px"></a-avatar>reCaptcha V3 <small>(cooming soon)</small></a-select-option>
                  <a-select-option key="recaptcha_enterprise" value="recaptcha_enterprise" disabled><a-avatar src="/images/recaptcha.png" style="margin-right: 5px"></a-avatar>reCaptcha Enterprise<small>(cooming soon)</small></a-select-option>
                </a-select>
              </a-form-item>
              <span class="custom-form-item-label" :style="{ display: 'inline-block', width: '50px', marginLeft: '12px', marginRight: '14px' }">
                Period:
              </span>
              <a-form-item class="inline-form-item" :style="{ display: 'inline-block', width: 'calc(50% - 76px)' }">
                <a-select :default-value="unlimited.period" v-model="unlimited.period">
                  <a-select-option key="7" value="7">7 days</a-select-option>
                  <a-select-option key="30" value="30">30 days</a-select-option>
                </a-select>
              </a-form-item>
            </a-form-item>
            <a-form-item class="a-from-item-label-left" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="Threads" >
              <a-slider v-model="unlimited.threads" :min="quantityMin" :max="quantityMax" />
            </a-form-item>
            <a-form-item>
              <span slot="label" v-if="unlimited.threads >= quantityMin && unlimited.threads <= quantityMax">{{`Number of Threads you will buy (${quantityMin} - ${quantityMax})`}}</span>
              <span slot="label" style="color: red" v-if="unlimited.threads < quantityMin || unlimited.threads > quantityMax">
                {{ `Number of Threads is invalid, must be (${quantityMin} - ${quantityMax})` }}
              </span>
              <a-input 
                v-model="unlimited.threads" 
                type="number"
              >
              </a-input>
            </a-form-item>
            <span>Price: <b>{{priceRange ? `$${priceRange.price}` : '~'}}</b>/Thread</span>
            <br>
            <span>Costs: <b>${{parseFloat(costs.toFixed(5))}}</b></span>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <a-modal v-model="visible.confirmBuy" :destroyOnClose="true" title="Confirm Buy Package!" :footer="null">
      <confirm-purchase v-if="payType == 'pay_per_use'" invoiceType="register" :type="type" :period="Number(period)" :quantity="quantity" :price="priceRange ? priceRange.price : 0.8" :amount="costs" @close="visible.confirmBuy = false"></confirm-purchase>
      <confirm-purchase v-if="payType == 'pay_per_thread'" invoiceType="register" :type="unlimited.type" :period="Number(unlimited.period)" :threads="unlimited.threads" :price="priceRange ? priceRange.price : 9.8" :amount="costs" @close="visible.confirmBuy = false"></confirm-purchase>
    </a-modal>
  </div>
</template>

<style scoped>
.form-inline {
  margin-bottom:0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .inline-form-item {
    display: block!important;
    width: 100%!important;
  }

  .custom-form-item-label {
    float: left;
    margin-left: 0!important;
  }
}
</style>

<script>
import ConfirmPurchase from './ConfirmPurchase.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ConfirmPurchase
  },
  name: 'PriceCaculator',
  data() {
    return {
      payType: 'pay_per_use',
      type: 'funcaptcha',
      period: '365',
      quantity: 1000,
      visible: {
        confirmBuy: false
      },
      loading: {
        purchase: false
      },
      unlimited: {
        type: 'image',
        period: '30',
        threads: 10,
      }
    }
  },
  mounted() {
    this.getPrices();
  },
  watch: {
    quantity(newValue) {
      this.quantity = Number(newValue);
    },
    unlimited: {
      handler(newValue){
        this.unlimited.threads = Number(newValue.threads);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('prices', {
      prices: "getPrices"
    }),

    ...mapGetters("auth", {
      isLoggedIn: "isLoggedIn"
    }),

    selectedPrice() {
      if (!this.prices || !this.prices.unlimited) {
        return null;
      }

      return this.payType == 'pay_per_use' ? this.prices[this.type].periods[this.period] : this.prices.unlimited[this.unlimited.type].periods[this.unlimited.period];
    },

    quantityMax() {
      if (!this.selectedPrice) {
        return 100;
      }

      return this.selectedPrice[this.selectedPrice.length - 1].max;
    },

    quantityMin() {
      if (!this.selectedPrice) {
        return 1000;
      }

      return this.selectedPrice[0].min;
    },

    priceRange() {
      if (!this.selectedPrice) {
        return undefined;
      }

      return this.payType == 'pay_per_use' 
        ? this.selectedPrice.find(range => range.min <= this.quantity && range.max >= this.quantity)
        : this.selectedPrice.find(range => range.min <= this.unlimited.threads && range.max >= this.unlimited.threads)
      ;
    },

    costs() {
      if (!this.priceRange) {
        return 0;
      }

      return this.payType == 'pay_per_use'
        ? this.quantity * (this.priceRange.price / 1000)
        : this.unlimited.threads * this.priceRange.price
      ;
    }
  },
  methods: {
    ...mapActions('prices', {
      getPrices: "getPrices"
    }),

    onInputQuantityChange() {
      if (this.payType == 'pay_per_use') {
        if (this.quantity > this.quantityMax) {
          this.quantity = this.quantityMax;
        }

        if (this.quantity < this.quantityMin) {
          this.quantity = this.quantityMin;
        }
      } else if (this.payType == 'pay_per_thread') {
        if (this.unlimited.threads > this.quantityMax) {
          this.unlimited.threads = this.quantityMax;
        }

        if (this.unlimited.threads < this.quantityMin) {
          this.unlimited.threads = this.quantityMin;
        }
      }
    },

    onclickPurchase() {
      if (!this.isLoggedIn) {
        const notiKey = 'warning-login' + Date.now();
        this.$notification.warning({
          message: 'You must login first!',
          description: 'You need to log in first to be able to do this.',
          btn: h => {
            return h(
              'a-button',
              {
                props: {
                  type: 'primary',
                  icon: 'forward'
                },
                on: {
                  click: () => {
                    this.$router.push({name: 'LoginPage'});
                    this.$notification.close(notiKey);
                  },
                },
              },
              'Goto Login',
            );
          },
          key: notiKey
        });
        return;
      }

      this.visible.confirmBuy = true;
    }
  }
}
</script>