<template>
  <a-table :data-source="types" :columns="columns" :pagination="false" :scroll="{x: 600}">
    <a slot="type" slot-scope="type, record">
      <a-avatar :src="record.image"></a-avatar>
      {{ type }}
    </a>
  </a-table>
</template>

<script>
export default {
  data() {
    return {
      types: [
        { type: 'Funcaptcha', image: '/images/funcaptcha.png', price: '$1.5 - $2', speed: '<10s', successRate: '99%' },
        { type: 'Image To Text', image: '/images/itt.png', price: '$0.05 - $0.2', speed: '<1s', successRate: '95%' },
        { type: 'reCAPTCHA v2', image: '/images/recaptcha.png', price: 'Coming soon', speed: 'Coming soon', successRate: 'Coming soon' },
        { type: 'reCAPTCHA v3', image: '/images/recaptcha.png', price: 'Coming soon', speed: 'Coming soon', successRate: 'Coming soon' },
        { type: 'reCAPTCHA Enterprise', image: '/images/recaptcha.png', price: 'Coming soon', speed: 'Coming soon', successRate: 'Coming soon' },
      ],
      columns: [
        { title: 'Captcha', dataIndex: 'type', key: 'type', scopedSlots: { customRender: 'type' }, },
        { title: 'Price/1000', dataIndex: 'price', key: 'price' },
        { title: 'Speed', dataIndex: 'speed', key: 'speed' },
        { title: 'Success Rate', dataIndex: 'successRate', key: 'successRate' },
      ],
    };
  },
};
</script>